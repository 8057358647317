#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

.logo {
  height: 38px;
  margin: 16px;
  background: black;
}

.ant-modal .ant-modal-content,
.ant-modal .ant-modal-title,
.ant-form .ant-form-vertical,
.ant-layout-header {
  background: #fff;
}

.ant-form-vertical .ant-form-item-label>label,
.ant-descriptions-item-label,
.ant-descriptions-item-content {
  font-weight: 500;
  color: black !important;
  font-weight: bold;
}

.ant-input-number {
  width: 100% !important;
}

.ant-picker {
  width: 100% !important;
}

.ant-picker-input>input[disabled],
.ant-select-selection-item,
.ant-input-disabled {
  color: black !important;
}

form {
  padding: 20px !important;
}

.ant-card-head {
  background: #0C7866 !important;
  color: #ffffff !important;
}

.ant-btn.ant-btn-sm {
  /* padding: 2px 11px 26px 11px; */
  font-size: 13px;
}

.row-even-color {
  background-color: #f0f0f0;
}

.row-odd-color {
  background-color: #ffffff;
}

.ant-table-wrapper .ant-table-tbody .ant-table-row>.ant-table-cell-row-hover {
  background: #e7e3e3;
}

.ant-popconfirm-title {
  color: #000 !important;
}

.ant-table-cell {
  min-width: 100px;
}

.ant-layout-sider {
  flex: 0 0 208px !important;
  max-width: 208px !important;
}
